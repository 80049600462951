import React from "react";
import { Link } from "gatsby";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import "../styles/pages/_lp.scss";
import "../styles/pages/_overal-styling.scss";
import hero_image1 from "../images/lp/TSD_Hero_2.png";
import hero_image2 from "../images/lp/TSD_Hero.png";
import heart from "../images/lp/TSD_Industry-Heart.svg";
import cat from "../images/lp/TSD_Valuable.jpg";
import overwhelmed from "../images/lp/TSD_Overwhelmed.png";

import icon_growth from "../images/lp/TSD_Industry-Growth.svg";
import icon_launch from "../images/lp/TSD_Industry-Launch.svg";
import icon_management from "../images/lp/TSD_Industry-Management.svg";

import sample1 from "../images/lp/TSD_Biz_cards_1.png";
import sample2 from "../images/lp/TSD_3L_Laptop.png";
import sample3 from "../images/lp/TSD_Coast_Fund_Report.png";
import sample4 from "../images/lp/TSD_Banner.png";
import sample5 from "../images/lp/TSD_Unified_Mobile.png";
import sample6 from "../images/lp/TSD_Veridian_Brochure.png";
import sample7 from "../images/lp/TSD_Fraser_Valley_Desktop.png";

import sample_bg1 from "../images/lp/TSD_Industry-Graphic_2.svg";
import sample_bg2 from "../images/lp/TSD_Industry-Graphic_1.svg";
import sample_bg3 from "../images/lp/TSD_Industry_Graphic_3.svg";

//Department Tabs
const openDepartment = (evt, cityName) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  console.log(tabcontent, "kkkk");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "flex";
  evt.currentTarget.className += " active";
};

const MarketingAccountingFirms = () => {
  return (
    <Layout type="lp marketin-stuck-lp template-lp">
      <SEO
        title="Marketing for Accounting Firms"
        description="As an accounting firm, you and your team should be focused on your clients – not your marketing. Let The Simple Department handle your marketing, from website design to digital ads to marketing support."
      />
      <section className="industry-intro">
        <div className="container">
          <h1 className="thin">
            Accounting <img className="border-img" src={hero_image2} /> Firms{" "}
            <img className="heart" src={heart} />
          </h1>
          <h1>
            The Simple <img className="border-img" src={hero_image1} />{" "}
            Department!
          </h1>
          <p className="small-padding top-space">
            As an accounting firm, you and your team should be focused on
            billable hours, completing files and spending time strategizing and
            consulting with your clients. Your marketing should help show off
            how smart, reliable and trustworthy you are – bringing new local
            businesses to your firm regularly.
          </p>
          <p>
            You should be able to focus on what you do best and know that your
            marketing is work the way it should – plain and simple.
          </p>
          <p className="top-space">
            <Link
              className="lp-btn blue-btn btn-blue-outline"
              to="#marketing-services"
            >
              Meet The Simple Department
            </Link>
          </p>
        </div>
      </section>
      <section className="industry-section2" id="marketing-services">
        <div className="ribbon">
          <div className="marquee">
            <p>
              ROI ⌃9% CPC ⌄6% SEO Rank ⌃3 CTR ⌃11% BR ⌄23% ROAS ⌃17% CMP ⌄2% CTR
              ⌃11% ROI ⌃9% CPC ⌄6% SEO Rank ⌃3 CTR ⌃11% BR ⌄23% ROAS ⌃17% CMP
              ⌄2% CTR ⌃11% ROI ⌃9% CPC ⌄6% SEO Rank ⌃3 CTR ⌃11% BR ⌄23% ROAS
              ⌃17% CMP ⌄2% CTR ⌃11%
            </p>
            <p>
              ROI ⌃9% CPC ⌄6% SEO Rank ⌃3 CTR ⌃11% BR ⌄23% ROAS ⌃17% CMP ⌄2% CTR
              ⌃11% ROI ⌃9% CPC ⌄6% SEO Rank ⌃3 CTR ⌃11% BR ⌄23% ROAS ⌃17% CMP
              ⌄2% CTR ⌃11% ROI ⌃9% CPC ⌄6% SEO Rank ⌃3 CTR ⌃11% BR ⌄23% ROAS
              ⌃17% CMP ⌄2% CTR ⌃11%
            </p>
          </div>
        </div>
        <div className="container">
          <img src={cat} />
          <div className="content-wrapper">
            <h2>Most Valuable Marketing Services for your Accounting Firm</h2>
            <p>
              While you keep the books, our marketing departments are built to
              keep your marketing operations in order.
            </p>
          </div>
        </div>
      </section>
      <section className="industry-department">
        <div className="container">
          <div className="tab-header">
            <button
              className="tablinks active"
              onClick={(e) => openDepartment(e, "launch_department")}
            >
              <img src={icon_launch} />
              The Launch <br></br>Department
            </button>
            <button
              className="tablinks"
              onClick={(e) => openDepartment(e, "growth_department")}
            >
              <img src={icon_growth} />
              The Growth <br></br>Department
            </button>
            <button
              className="tablinks"
              onClick={(e) => openDepartment(e, "management_department")}
            >
              <img src={icon_management} />
              The Management <br></br>Department
            </button>
          </div>
          <div className="tab-body">
            <div
              id="launch_department"
              class="tabcontent"
              style={{ display: "flex" }}
            >
              <div className="department-item">Simple Websites</div>
              <div className="department-item">Basic Brand Development</div>
              <div className="department-item">SEO Services</div>
            </div>

            <div id="growth_department" class="tabcontent">
              <div className="department-item">Growth Marketing</div>
              <div className="department-item">Website SEO</div>
              <div className="department-item">Digital Advertising</div>
            </div>

            <div id="management_department" class="tabcontent">
              <div className="department-item">Martech Management</div>
              <div className="department-item">Content Planning & Strategy</div>
              <div className="department-item">Marketing Consulting</div>
            </div>
          </div>
        </div>
      </section>
      <section className="industry-sample-work">
        <div className="container">
          <h2>Sampling of Our Creations</h2>
          <p>
            Now, do not expect the same results from this small collection of
            online creations of ours. There is so much that goes into the final
            result that depends on your brand, visual language, content and of
            course – your budget. So what we have tried to showcase is a full
            spectrum of results, based on all of those factors.
          </p>
        </div>
        <div className="sample-container">
          <div className="image-wrapper accounting">
            <img src={sample1} className="sample1" />
            <img src={sample2} className="sample2" />
            <img src={sample3} className="sample3" />
            <img src={sample4} className="sample4" />
            <img src={sample5} className="sample5" />
            <img src={sample6} className="sample6" />
            <img src={sample7} className="sample7" />
          </div>
          <img src={sample_bg1} className="sample-bg1" />
          <img src={sample_bg2} className="sample-bg2" />
          <img src={sample_bg3} className="sample-bg3" />
        </div>
      </section>
      <section className="overwhelmed">
        <img src={overwhelmed} />
        <div className="container row-wrapper">
          <h3>Feeling Overwhelmed?</h3>
          <p>
            The way you look at numbers for a business is the same way we look
            at marketing – so all of this is second nature for us. There are
            many ways we can work with you.
          </p>
          <p>
            If you know what you want, please request a{" "}
            <a href="#free-quote">FREE customized quote</a>
            from our team of experts.
          </p>
          <p>
            If you are unsure what your accounting firm really needs, we suggest
            booking a 1-hour marketing consultation with our CMO to review your
            goals (and vision!) and recommend the best products and services for
            you, and then create a customized quote. You can book a 1-hour
            consultation <span className="link-darkblue underline">here</span>,
            which will launch a chat with our team to book your time. Please
            note, a 1-hour consultation is $250 CAD + tax, which will be
            collected at the time of booking.
          </p>
        </div>
      </section>
      <section
        className="da-lp-hero pre-paid-design-footer industry-footer"
        id="free-quote"
      >
        <div className="container">
          <h2>Get Your FREE Personalized Quote</h2>
          <p className="text">
            Let our team know where your business is stuck, or what you need
            help with and we’ll put together a FREE personalized quote, mixing
            and matching from our existing services and tailoring them to your
            specific needs.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <a className="lp-btn white-btn-blue-text">
                Get Your Free Quote Now
              </a>
            </strong>
          </p>
          <p className="sub-text">
            <i>
              This will launch our chat so you can speak directly to our team
              about your business.
            </i>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default MarketingAccountingFirms;
